<template>
  <form class="form" @submit.prevent="submit">
    <div class="dialog-body">
      <p style="color: red;">{{errorText}}</p>
      <Input
          v-model="model.value"
          :label="label"
          :state="!validation.hasError('model.value')"
          :error-text="validation.firstError('model.value')"
      ></Input>
    </div>
    <div class="dialog-footer">
      <div class="dialog__btns">
        <Button type="button" @click="$emit('cancel')">{{$t('actions.cancel')}}</Button>
        <Button visual-style="fill" :loading="isLoading">{{$t('actions.save')}}</Button>
      </div>
    </div>
  </form>
</template>

<script>
import SimpleVueValidator from 'simple-vue-validator';
import ApiService from "@/services/api.service";

const Validator = SimpleVueValidator.Validator;

export default {
  props:{
    value: {
      type: [String]
    },
    field: {
      type: [String]
    },
    label: {
      type: [String]
    },
    valid: {
      type: [String],
      default(){
        return 'default';
      }
    },
  },
  components: {
  },
  data() {
    return {
      model:{
        value:''
      },
      errorText:'',
      submitted:false,
      isLoading:false
    }
  },
  methods:{
    submit(){

      this.submitted = true;

      this.$validate().then(success => {
            if (success) {

              this.isLoading=true;

              let sendData = {};
              sendData[this.field] = this.model.value;

              ApiService.editContact(sendData).then((resp)=>{
                this.$emit('success',resp.data);
                this.isLoading=false;
              }).catch((err)=>{
                let data = err.data;
                this.errorText = data.errorText;
                this.isLoading=false;
              });

            }
          }
      )
    }
  },
  created() {
    this.model.value=this.value;
  },
  validators: {
    "model.value": function (value) {
      if (this.submitted){

        if(this.valid=='email'){
          return Validator.value(value).required(this.$t('errors.required')).email(this.$t('errors.email'));
        }
        else{
          return Validator.value(value).required(this.$t('errors.required'));
        }
      }
    }
  },
  mixins: [SimpleVueValidator.mixin]
}
</script>
