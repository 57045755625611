<template>
  <form class="form" @submit.prevent="submit">
    <div class="dialog-body">
      <p style="color: red;">{{errorText}}</p>
      <Input
          v-model="model.curPassword"
          :label="$t('components.profile.editPassword.labelCurPassword')"
          type="password"
          :state="!validation.hasError('model.curPassword')"
          :error-text="validation.firstError('model.curPassword')"
          :requred="true"
      ></Input>
      <Input
          v-model="model.password"
          :label="$t('components.profile.editPassword.labelPassword')"
          type="password"
          :state="!validation.hasError('model.password')"
          :error-text="validation.firstError('model.password')"
          :requred="true"
          :note="$t('components.profile.editPassword.notePassword')"
      ></Input>
      <Input
          v-model="model.repPassword"
          :label="$t('components.profile.editPassword.labelRepPassword')"
          type="password"
          :state="!validation.hasError('model.repPassword')"
          :error-text="validation.firstError('model.repPassword')"
          :requred="true"
      ></Input>
    </div>
    <div class="dialog-footer">
      <div class="dialog__btns">
        <Button type="button" @click="$emit('cancel')">{{$t('actions.cancel')}}</Button>
        <Button visual-style="fill" :loading="isLoading">{{$t('actions.save')}}</Button>
      </div>
    </div>
  </form>
</template>

<script>
import SimpleVueValidator from 'simple-vue-validator';
import ApiService from "@/services/api.service";
import {mapMutations} from "vuex";

const Validator = SimpleVueValidator.Validator;



export default {
  props: ['value','field','label'],
  components: {
  },
  data() {
    return {
      model:{
        curPassword:'',
        password:'',
        repPassword:''
      },
      errorText:'',
      submitted:false,
      isLoading:false
    }
  },
  methods:{
    ...mapMutations({
      loginSuccess: "auth/loginSuccess",
    }),
    submit(){

      this.submitted = true;

      this.$validate().then(success => {
            if (success) {

              this.isLoading=true;

              ApiService.changePassword(this.model).then((resp)=>{

                this.loginSuccess(resp.data);
                this.$emit('success',{});
                this.isLoading=false;
              }).catch((err)=>{
                let data = err.data;
                this.errorText = data.errorText;
                this.isLoading=false;
              });

            }
          }
      )
    }
  },
  created() {
    this.model.value=this.value;
  },
  validators: {
    "model.curPassword": function (value) {
      if(this.submitted)
        return Validator.value(value).required(this.$t('errors.required'));
    },
    "model.password": function (value) {
      if(this.submitted)
        return Validator.value(value).required(this.$t('errors.required')).regex('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}',this.$t('errors.password'));
    },
    'model.repPassword, model.password': function (repeat, password) {
      if (this.submitted || this.validation.isTouched('repeat')) {
        return Validator.value(repeat).required(this.$t('errors.required')).match(password,this.$t('errors.repPassword'));
      }
    },
  },
  mixins: [SimpleVueValidator.mixin]
}
</script>
