<template>
	<div class="profile__info-content">
		<div class="profile-title">{{$t('profile.personal.title')}}</div>
		<div class="profile__subtitle">{{$t('profile.personal.description')}}</div>
		<div class="profile__parts">
			<div class="profile__part profile__part-name">
				<div class="profile__part-title">{{ $t('profile.personal.fio') }}</div>
				<div class="profile__part-row">
					<div class="profile__part-key">{{ $t('profile.personal.firstName') }}:</div>
					<div class="profile__part-value">
						<div class="profile__part-value-text">{{ contact.FirstName }}</div>
						<button class="profile__part-value-btn btn btn__secondary btn__secondary_outline-green" @click="openModalFirstName = true">{{$t('actions.edit')}}</button>
					</div>
				</div>
				<div class="profile__part-row">
					<div class="profile__part-key">{{ $t('profile.personal.lastName') }}:</div>
					<div class="profile__part-value">
						<div class="profile__part-value-text">{{ contact.LastName }}</div>
						<button class="profile__part-value-btn btn btn__secondary btn__secondary_outline-green" @click="openModalLastName = true">{{$t('actions.edit')}}</button>
					</div>
				</div>
			</div>

			<div class="profile__part profile__part-login">
				<div class="profile__part-title">{{$t('profile.personal.loginAndPassword')}}</div>
				<div class="profile__part-row">
					<div class="profile__part-key">{{$t('profile.personal.login')}}:</div>
					<div class="profile__part-value">
						<div class="profile__part-value-text">{{contact.MobilePhone}}</div>
					</div>
				</div>
				<div class="profile__part-row">
					<div class="profile__part-key">{{$t('profile.personal.password')}}:</div>
					<div class="profile__part-value">
						<button class="profile__part-value-btn btn btn__secondary btn__secondary_outline-green"  @click="openModalPassword = true">{{$t('actions.edit')}}</button>
					</div>
				</div>
				<div class="profile__part-subtitle">{{$t('profile.personal.passwordNote')}}</div>
			</div>

			<div class="profile__part profile__part-bday">
				<div class="profile__part-title">{{$t('profile.personal.birthday')}}</div>
				<div class="profile__part-row">
					<div class="profile__part-value">
						<div class="profile__part-value-text">{{formatDateBirthDate(contact.BirthDate)}}</div>
					</div>
				</div>
				<div class="profile__part-subtitle">{{$t('profile.personal.birthdayNote')}}</div>
			</div>
		</div>

		<Dialog
      buttonsType="save"
      v-if="openModalFirstName"
			@close="openModalFirstName = false"
		>
			<div slot="header">
				<h3>{{$t('profile.personal.modalEditFirstName')}}</h3>
			</div>
			<div slot="body">
        <EditFields
            field="firstName"
            :label="$t('profile.personal.firstName')"
            :value="contact.FirstName"
            @cancel="openModalFirstName=false"
            @success="(data)=>{success(data);openModalFirstName=false;}"
        ></EditFields>
			</div>
		</Dialog>

    <Dialog
        buttonsType="save"
        v-if="openModalLastName"
        @close="openModalLastName = false"
    >
      <div slot="header">
        <h3>{{$t('profile.personal.modalEditLastName')}}</h3>
      </div>
      <div slot="body">
        <EditFields
            field="lastName"
            :label="$t('profile.personal.lastName')"
            :value="contact.LastName"
            @cancel="openModalLastName=false"
            @success="(data)=>{success(data);openModalLastName=false;}"
        ></EditFields>
      </div>
    </Dialog>

    <Dialog
        buttonsType="save"
        v-if="openModalPassword"
        @close="openModalPassword = false"
    >
      <div slot="header">
        <h3>{{$t('profile.personal.modalEditPassword')}}</h3>
      </div>
      <div slot="body">
        <EditPassword
            @cancel="openModalPassword=false"
            @success="()=>{openModalPassword=false;}"
        ></EditPassword>
      </div>
    </Dialog>
	</div>
</template>

<script>
import {mapGetters,mapMutations} from "vuex";

import Dialog from '@/components/Controlls/Dialog';
import EditFields from '@/components/profile/EditFields';
import EditPassword from "@/components/profile/EditPassword";

export default {
	data() {
		return {
			openModalFirstName: false,
      openModalLastName: false,
      openModalPassword: false,
		}
	},
	components: {
		Dialog,
    EditFields,
    EditPassword
	},
  mounted() {
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      contact: "contact/contact"
    })
  },
  methods:{
    ...mapMutations({
      setContact:'contact/setContact'
    }),
    formatDateBirthDate(date){

      if(!date)
        return '';

      let d = new Date(date);

      return [d.getDate()<10?'0'+d.getDate():d.getDate(),(d.getMonth()+1)<10?'0'+(d.getMonth()+1):(d.getMonth()+1)].join('.');
    },
    success(data){
      this.setContact(data)
    }
  }
}
</script>
